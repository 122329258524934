import { Route } from '@angular/router';

export const PAGES_ROUTES: Route[] = [
    {
        path: 'superadmin',
        title: 'Суперадмин',
        data: {
            isNav: true,
            icon: 'crown',
            navTitle: 'Суперадмин',
            permissionId: 'superadmin',
        },
        loadComponent: () =>
            import('@brandPages/superadmin/superadmin.component').then(
                (m) => m.SuperadminComponent
            ),
    },
    {
        path: 'drugs',
        title: 'Товары',
        data: {
            isNav: true,
            icon: 'drugs',
            navTitle: 'Товары',
            permissionId: 'drugs',
        },
        loadComponent: () =>
            import('@brandPages/drugs/drugs-list/drugs.component').then(
                (m) => m.DrugsComponent
            ),
    },
    {
        path: 'drugs/:id',
        title: 'Редактирование товара',
        data: {
            permissionId: 'drugs',
        },
        loadComponent: () =>
            import(
                '@brandPages/drugs/drugs-detail/drugs-detail.component'
            ).then((m) => m.DrugsDetailComponent),
    },
    {
        path: 'dialogs',
        title: 'Диалоги',
        data: {
            isNav: true,
            icon: 'dialog',
            navTitle: 'Диалоги',
            permissionId: 'dialogs',
            badgeSelector: 'dialogBadge',
        },
        loadChildren: () =>
            import('@brandPages/dialogs/dialogs.routes').then(
                (mod) => mod.DIALOGS_ROUTES
            ),
    },
    {
        path: 'analytica',
        title: 'Аналитика',
        data: {
            isNav: true,
            icon: 'graph',
            navTitle: 'Аналитика',
            permissionId: 'analytics',
        },
        loadComponent: () =>
            import('@brandPages/analytics/analytics.component').then(
                (m) => m.AnalyticsComponent
            ),
    },
    {
        path: 'analytics',
        title: 'Аналитика',
        data: {
            isNav: true,
            icon: 'graph',
            navTitle: 'Аналитика',
            permissionId: 'analytics-v2',
        },
        loadComponent: () =>
            import('@brandPages/analytics-v2/analytics.component').then(
                (m) => m.AnalyticsComponent
            ),
    },
    {
        path: 'megarelease',
        title: 'МегаРелиз',
        data: {
            isNav: true,
            icon: 'm-article',
            navTitle: 'МегаРелиз',
            permissionId: 'megarelease',
        },
        loadComponent: () =>
            import(
                '@brandPages/megarelease/megarelease-list/megarelease-list.component'
            ).then((m) => m.MegareleaseListComponent),
    },
    {
        path: 'logout',
        title: 'Выход',
        data: {
            isNav: true,
            icon: 'exit',
            navTitle: 'Выйти',
            permissionId: 'everyone',
        },
        loadComponent: () =>
            import('@brandPages/logout/logout.component').then(
                (m) => m.LogoutComponent
            ),
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/drugs',
    },
];
